<!-- This flex container takes the full height -->
<div class="d-flex flex-column">
  <header>
    <app-header></app-header>
  </header>
  <main class="d-flex flex-row m-2 p-0 fondo_color">
    <app-navbar></app-navbar>
    <div class="d-flex flex-fill flex-column min-vh-100 fondo_padding">
      <!-- Breadcrumb -->
      <router-outlet></router-outlet>
    </div>
  </main>
  <footer class="page-footer font-small blue mt-auto">
    <app-footer></app-footer>
  </footer>
</div>