
<!-- You can change the values `dark` here with any of the following: -->
<!-- dark, light, primary, secondary, success, info, danger, warning -->
<nav class="navbar navbar-dark top-nav">
  <div class="container">
  <!-- This is the application title with a link to the root -->
    <img class="top-nav__logo" src="assets/img/landing/logo_procel_blanco.png" alt="Logotipo procel">
    <!-- <a class="navbar-brand" routerLinkActive="active" routerLink="/">Angular & Bootstrap</a> -->
    <!-- This is a spacer so the links move to the end -->
    <div class="mr-auto"></div>
    <!-- This main navigation links are defined here -->
    <div class="navbar-expand">
      <div class="navbar-nav">
        <!-- Each link has the routerLink property set to a different route -->
        <a class="nav-item nav-link mr-3" routerLinkActive="active" routerLink="/home">
          <strong>Inicio</strong>
        </a>
        <a class="nav-item nav-link mr-3" routerLinkActive="active" routerLink="/login">
          <strong>Entrar</strong>
        </a>
        <!-- <button class="btn btn-header" routerLinkActive="active" routerLink="/login">
          Entrar
        </button> -->
      </div>
    </div>
  </div>
</nav>