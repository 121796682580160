import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RespuestaOAuthKC } from '@app/model/respuesta-oauth-kc.model';
import { RespuestaOAuth } from '@app/model/respuesta-oauth.model';
import { Respuesta } from '@app/model/respuesta.model';
import { UsuarioOAuthKC } from '@app/model/usuario-oauth-kc.model';
import { Usuario } from '@app/model/usuario.model';
import { DataService } from '@app/service/data.service';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class AutorizacionService {
  private usuarioV1Url = '/v1/usuario/';

  private userSubject: BehaviorSubject<UsuarioOAuthKC>;
  public user: Observable<UsuarioOAuthKC>;
  public respuestaOAuthKC: RespuestaOAuthKC;
  public nombreUsuario: string = '';

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dataService: DataService) {
    this.userSubject = new BehaviorSubject<UsuarioOAuthKC>(JSON.parse(localStorage.getItem('userInfo') || '{}'));
    this.user = this.userSubject.asObservable();
    this.respuestaOAuthKC = new RespuestaOAuthKC(new RespuestaOAuth());
  }

  public get userValue(): UsuarioOAuthKC {
    return this.userSubject.value;
  }

  getAccessToken() {
    return sessionStorage.getItem('accessToken');
  }

  getRefreshToken() {
    return sessionStorage.getItem('refreshToken')!;
  }

  login(nombreUsuario: string, clave: string) {
    // const claveEncriptada: string = CryptoJS.SHA256(clave).toString();
    // let plainText: string = '';
    // const claveEncriptada2: string = CryptoJS.AES.encrypt(plainText, clave).toString();
    // console.log("clave encriptada: " + claveEncriptada + ", " + clave + ", " + claveEncriptada2);

    // let url = environment.oauthURL + '/realms/' + environment.keycloak.realm + '/protocol/openid-connect/token';
    let url = this.usuarioV1Url + 'login';

    let usuario: Usuario = new Usuario();
    usuario.nombreUsuario = nombreUsuario;
    usuario.password = clave;

    // let headersParam = new HttpHeaders()
    //   .set('Content-Type', 'application/x-www-form-urlencoded');

    // let paramsParam = new HttpParams()
    //   .set('grant_type', 'password')
    //   .set('client_id', environment.keycloak.clientId)
    //   .set('client_secret', environment.keycloak.clientSecret)
    //   .set('username', nombreUsuario)
    //   .set('password', clave);

    return this.httpClient
      // .post<RespuestaOAuth>(url, paramsParam)
      .post<Respuesta<RespuestaOAuth>>(url, JSON.stringify(usuario))
      .pipe(map(respuesta => {

        if (!respuesta.error) {
          let resp: RespuestaOAuth = respuesta.data;
          let rkc!: RespuestaOAuthKC;

          if (resp) {
            rkc = new RespuestaOAuthKC(resp);

            if (resp.usuario) {
              sessionStorage.setItem('usuario', JSON.stringify(resp.usuario));
            }
          }

          return this.procesarRespuesta(rkc);
        } else {
          throw Error(respuesta.mensaje);
        }

      }));
  }

  // private loginCall(url: string, body: HttpParams) {
  //   this.httpClient.post<RespuestaOAuth>(
  //       url
  //       , body
  //     )
  //     .pipe(map(data => {
  //       this.procesarRespuesta(data);
  //       return data;
  //     }));
  // }

  userInfoCall() {
    const url = environment.oauthURL + '/realms/' + environment.keycloak.realm + '/protocol/openid-connect/userinfo';
    const body = new HttpParams()
      .set('access_token', this.getAccessToken()!);

    return this.httpClient.post<UsuarioOAuthKC>(
      url
      , body
    )
      .pipe(map(user => {
        if (user) {
          sessionStorage.setItem('userInfo', JSON.stringify(user));
          this.userSubject.next(user);

          this.respuestaOAuthKC.usuarioOAuthKC = user;
        }
        return user;
      }));
  }

  tieneAccessTokenValido() {
    if (this.getAccessToken()) {
      const expiresAt = sessionStorage.getItem('atExpiracion');
      const now = new Date();
      if (expiresAt && parseInt(expiresAt) < now.getTime()) {
        return false;
      }
      return true;
    }
    return false;
  }

  tieneRefreshTokenValido() {
    if (this.getRefreshToken()) {
      const expiresAt = sessionStorage.getItem('rtExpiracion');
      // console.log("expires at: " + expiresAt);
      const now = new Date();
      if (expiresAt && parseInt(expiresAt) < now.getTime()) {
        return false;
      }
      return true;
    }
    return false;
  }

  refrescarToken(): Observable<any> {
    let url = environment.oauthURL + '/realms/' + environment.keycloak.realm + '/protocol/openid-connect/token';
    let body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('client_id', environment.keycloak.clientId)
      .set('client_secret', environment.keycloak.clientSecret)
      .set('refresh_token', this.getRefreshToken());

    sessionStorage.setItem('refreshtoken', 'true');
    return this.httpClient.post<RespuestaOAuthKC>(
      url
      , body
    )
      .pipe(map(data => {
        return this.procesarRespuesta(data);
      }));
  }

  logout() {
    console.log("logout");
    const url = environment.oauthURL + '/realms/' + environment.keycloak.realm + '/protocol/openid-connect/logout?redirect_uri=' + environment.keycloak.redirectUri;
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('client_id', environment.keycloak.clientId)
      .set('client_secret', environment.keycloak.clientSecret)
      .set('refresh_token', this.getRefreshToken());

    return this.httpClient.post<RespuestaOAuthKC>(
      url
      , body
    )
      .pipe(map(data => {
        console.log("deslogueado...");
        this.clearVars();
        return data;
      }));
  }

  clearVars() {
    this.userSubject.next(new UsuarioOAuthKC());
    sessionStorage.clear();
    localStorage.clear();
  }

  expirarSesion(): void {
    () => this.logout();
    this.clearVars();
  }

  desconexion(): void {
    console.log("desconexion");
    this.clearVars();
    this.router.navigate(['/login']);
    // this.logout().pipe(first())
    // .subscribe(data => {
    //   console.log("after logout");
    //   this.router.navigate(['/login']);
    // });
  }

  denegarAccesso(): void {
    this.router.navigate(['/']);
    this.clearVars();
  }


  private manipularError(error: any): Promise<any> {
    return Promise.reject(error.mensaje || error.error_description || error);
  }


  private procesarRespuesta(respuesta: RespuestaOAuthKC) {
    const prefix = '[AutorizacionService.procesarRespuesta] ';
    let respuestaOAuth = new RespuestaOAuth();
    if (respuesta) {
      if (!respuesta.error) {
        const now = new Date();
        sessionStorage.setItem('horaIngreso', now.toString());
        if (respuesta.access_token) {
          sessionStorage.setItem('accessToken', respuesta.access_token);
          const atExpiracionMilisegundos = respuesta.expires_in * 1000;
          const atExpiracion = now.getTime() + atExpiracionMilisegundos;
          sessionStorage.setItem('atExpiracion', '' + atExpiracion);
        }
        if (respuesta.refresh_token) {
          sessionStorage.setItem('refreshToken', respuesta.refresh_token);
          const rtExpiracionMilisegundos = respuesta.refresh_expires_in * 1000;
          const rtExpiracion = now.getTime() + rtExpiracionMilisegundos;
          sessionStorage.setItem('rtExpiracion', '' + rtExpiracion);
        }
      } else {
        console.log(prefix + 'respuesta con error');
      }
    }
    return respuestaOAuth;
  }
}
