import { NgModule } from '@angular/core';
import { LayoutWebsiteComponent } from './layout-website/layout-website.component';
import { HeaderWebsiteComponent } from './header-website/header-website.component';
import { FooterWebsiteComponent } from './footer-website/footer-website.component';
import { RouterModule } from '@angular/router';
import { ComunModule } from '@app/common/comun.module';



@NgModule({
  declarations: [
    LayoutWebsiteComponent,
    HeaderWebsiteComponent,
    FooterWebsiteComponent
  ],
  imports: [
    ComunModule,
    RouterModule
  ]
})
export class UiWebsiteModule { }
