<nav class="navbar navbar-dark top-nav" style="padding: 0 30px;">
  <img class="top-nav__logo" src="assets/img/landing/logo_procel_blanco.png" alt="Logotipo procel">
  <div class="mr-auto"></div>
  <div class="navbar-expand">
    <div class="navbar-nav">
      <button (click)="submit()" type="submit" class="btn btn-link" style="color: #FFF;">
        <img src="assets/img/icon/sign-out.png" alt="salir" class="mr-1"> Salir
      </button>
    </div>
  </div>
</nav>