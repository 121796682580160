import { Component, OnInit } from '@angular/core';
import { Funcion } from '@app/model/funcion.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  funcionesGenerales!: Funcion[];
  clienteNavbar: boolean;

  constructor() { 
    this.clienteNavbar = false;
  }

  ngOnInit(): void {
    this.clienteNavbar = JSON.parse(sessionStorage.getItem('perfilId')!).id === 10;
    this.funcionesGenerales = JSON.parse(sessionStorage.getItem('funcionesGenerales')!);
  }

}
