import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEs from '@angular/common/locales/es-CL';
import { LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComunModule } from './common/comun.module';
// import { ErrorInterceptor } from './http/error.interceptor';
import { AutorizacionGuard } from './core/security/autorizacion.guard';
import { JwtInterceptor } from './http/jwt.interceptor';
import { UiSmartlokusModule } from './ui/smartlokus/ui.smartlokus.module';
import { UiWebsiteModule } from './ui/website/ui-website.module';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UiWebsiteModule,
    UiSmartlokusModule,
    ComunModule,
    ReactiveFormsModule,
  ],
  providers: [
    AutorizacionGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-CL'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
