import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AlertType } from '@app/enums/alert-type';
import { Alert } from '@app/model/alert.model';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new Subject<Alert>();
  private defaultId = 'default-alert';

  // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    success(message: string, titulo: string, options?: any) {
        //this.alert(new Alert({ ...options, type: AlertType.Success, message }));
        return Swal.fire(
            {
                title: titulo,
                text: message,
                icon: 'success',
                confirmButtonText: 'ACEPTAR',
                allowOutsideClick: false,
            }
        );
    }

    error(message: string, titulo: string, options?: any) {
        //this.alert(new Alert({ ...options, type: AlertType.Error, message }));
        return Swal.fire(
            {
                title: titulo,
                text: message,
                icon: 'error',
                confirmButtonText: 'ACEPTAR',
                allowOutsideClick: false,
            }
        );
    }

    info(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Info, message }));
    }

    warn(message: string, options?: any) {
        this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
    }

    // main alert method    
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }
}
