import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutWebsiteComponent } from './ui/website/layout-website/layout-website.component';
import { LayoutComponent } from './ui/smartlokus/layout/layout.component';

import { AuthGuard } from '@app/http/auth.guard';

const routes: Routes = [
  // If this path is the 'full' match... redirect to this route.
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: '', component: LayoutWebsiteComponent,
    children: [
      // home es la página principal de la web.
      // login es la ventana de inicio de sesion montada en la web.
      { path: 'home', loadChildren: () => import('./component/home/home.module').then(m => m.HomeModule) },
      { path: 'login', loadChildren: () => import('./component/login/login.module').then(m => m.LoginModule) }
    ]
  },
  //Paginas de applicacion deben ir aca
  // { path: 'dashboard', data: { breadcrumb: 'dashboard' }, component: LayoutComponent,
  //   children: [
  //     { path: '', loadChildren: () => import('./component/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard] },
  //   ]
  // },
  { path: 'app', component: LayoutComponent,
    children: [
      { path: 'dashboard', data: { breadcrumb: 'dashboard' }, loadChildren: () => import('./component/dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'proyectos', data: { breadcrumb: 'proyectos' }, loadChildren: () => import('./component/proyecto/proyecto.module').then(m => m.ProyectoModule) },
      { path: 'clientes', data: { breadcrumb: 'clientes' }, loadChildren: () => import('./component/cliente/cliente.module').then(m => m.ClienteModule) },
      { path: 'territorios', data: { breadcrumb: 'territorios' }, loadChildren: () => import('./component/territorio/territorio.module').then(m => m.TerritorioModule) },
      { path: 'estadisticas', data: { breadcrumb: 'estadisticas' }, loadChildren: () => import('./component/estadistica/estadistica.module').then(m => m.EstadisticaModule) },
      { path: 'informes', data: { breadcrumb: 'informes' }, loadChildren: () => import('./component/informe/informe.module').then(m => m.InformeModule) },
      { path: 'infraestructuras', data: { breadcrumb: 'infraestructuras' }, loadChildren: () => import('./component/infraestructura/infraestructura.module').then(m => m.InfraestructuraModule) },
      // { path: 'imgypln', data: { breadcrumb: 'imagenesyplanos' }, loadChildren: () => import('./component/imagenesyplanos').then(m => m.ImagenyplanoModule) },
      { path: 'configuracion', data: { breadcrumb: 'configuracion' }, loadChildren: () => import('./component/configuracion/configuracion.module').then(m => m.ConfiguracionModule) },
    ],
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
