import { Cliente } from "./cliente.model";
import { Perfil } from "./perfil.model";
import { Persona } from "./persona.model";

export class Usuario {
    id!: number;
    nombreUsuario!: string;
    password!: string;
    descripcion!: string;
    email!: string;
    telefono!: string;
    activo!: boolean;
    persona!: Persona;
    clientes!: Cliente[];
    perfiles!: Perfil[];
}