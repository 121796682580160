import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AutorizacionService } from './autorizacion.service';
declare var swal: any;

@Injectable()
export class AutorizacionGuard implements CanActivate {

    private sesionVencio = false;

    constructor(
        private router: Router,
        private authService: AutorizacionService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        if (this.authService.tieneRefreshTokenValido()) {
            // logeado
            return true;
        }
        // no logeado redirigir a login
        if (this.authService.getAccessToken()) {
            this.authService.expirarSesion();
        }
        
        return false;
    }
}