import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaderResponse, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, throwError } from "rxjs";
import { environment } from "@environments/environment";
import { AutorizacionService } from "@app/core/security/autorizacion.service";
import { catchError, filter, finalize, first, switchMap, take } from 'rxjs/operators';
import { OAuthErrors } from '@app/enums/oauth-errors';
import { CargandoService } from "@app/service/cargando.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private AUTH_HEADER = "Authorization";
    private refreshTokenInProgress = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    constructor(
        private autorizacionService: AutorizacionService,
        private cargandoService: CargandoService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!request.url.startsWith(environment.keycloak.url)) {
            if (!request.headers.has('Content-Type')) {
                request = request.clone({
                    headers: request.headers
                    .set('Content-Type', 'application/json')
                    .set('Access-Control-Allow-Origin', '*')
                    .set('Accept', 'application/json')
                });
            } else if (!request.headers.has('CUSTOM')) {
                request = request.clone({
                    headers: request.headers
                    .delete('Content-Type')
                    .set('Access-Control-Allow-Origin', '*')
                    .set('Accept', 'application/json')
                })
            } else {
                request = request.clone({
                    headers: request.headers
                    .delete('CUSTOM')
                    .set('Access-Control-Allow-Origin', '*')
                })
            }

            request = this.addAuthenticationToken(request);
        } else {
            request = request.clone({
                headers: request.headers.set('Content-Type', 'application/x-www-form-urlencoded')
            });
        }
        
        this.cargandoService.mostrar(request.url);
        
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = 'Error desconocido. Contacte al Administrador';
                    if (error.status) {
                        console.log("Error.status: " + error.status);
                    }
                    
                    if (error && error.status === 400) {
                        if (error.error && error.error.error && OAuthErrors.INVALID_GRANT == error.error.error) {
                            console.log("Interceptor Error 400. Invalid Grant: " + error.error.error_description);
                            errorMessage = "Sesión terminada. Vuelva a ingresar.";
                            this.autorizacionService.desconexion();
                        }
                    } else if (error && error.status === 401) {
                        if (error.error && error.error.error && OAuthErrors.INVALID_GRANT == error.error.error) {
                            console.log("Interceptor Error 401. Invalid Grant: " + error.error.error_description);
                            errorMessage = "Error en las credenciales. Verifique usuario y/o Contraseña";
                            this.autorizacionService.desconexion();
                        } else if (error.error && error.error.error && OAuthErrors.INVALID_TOKEN == error.error.error) {
                            console.log("Invalid Token: " + error.error.error_description);
                            errorMessage = "Código de acceso no válido";

                        } else if (error.error && error.error.error && OAuthErrors.UNAUTHORIZED == error.error.error) {
                            console.log("Unauthorized: " + error.error.error_description);
                            errorMessage = "Acceso no autorizado";
                        } else {
                            // 401 errors are most likely going to be because we have an expired token that we need to refresh.
                            if (this.refreshTokenInProgress) {
                                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                                // which means the new token is ready and we can retry the request again
                                return this.refreshTokenSubject.pipe(
                                    filter(result => result !== null),
                                    take(1),
                                    switchMap(() => next.handle(this.addAuthenticationToken(request)))
                                );
                            } else {
                                this.refreshTokenInProgress = true;
                                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                                this.refreshTokenSubject.next(null);

                                return this.autorizacionService.refrescarToken().pipe(
                                    switchMap((success: boolean) => {               
                                        this.refreshTokenSubject.next(success);
                                        return next.handle(this.addAuthenticationToken(request));
                                    }),
                                    // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                                    // for the next time the token needs to be refreshed
                                    finalize(() => this.refreshTokenInProgress = false)
                                );
                            }
                        }
                    } else if (error.status === 403) {
                        // acceso no autorizado a recurso
                        console.log(`Error Status: ${error.status}\nMessage: ${error.message}`);
                        errorMessage = 'No tiene autorización para acceder a este recurso';
                    } else if (error.status === 0) {
                        // Cuando la URL es a Keycloak, error = 0 significará problema en el login lo cual para este caso se interpretará como credenciales incorrectas
                        // Si la URL es a la API, error = 0 significará problema al llamar al backend.
                        if (error.url && error.url.startsWith(environment.keycloak.url)) {
                            console.log("Invalid Grant");
                            errorMessage = "Error en las credenciales. Verifique usuario y/o Contraseña";
                        } else {
                            console.log("Error llamada API");
                            errorMessage = "Error al llamar al backend";
                            this.autorizacionService.desconexion();
                        }
                        
                    } else if (error.status === 406) {
                        if (error.error) {
                            console.log(`Error Status: ${error.status}\nMessage: ${error.error.mensaje}`);
                            errorMessage = `Status: ${error.status}\nError: ${error.error.mensaje}`;
                        } else {
                            errorMessage = "Error 406. Contacte Administrador";
                        }
                    } else {
                        if (error.error) {
                            console.log("Mensaje Error: " + error.error.mensaje);
                        } else {
                            console.log("Mensaje Error: " + error.message);
                        }
                        errorMessage = `${errorMessage}\nStatus: ${error.status}`;
                    }

                    return throwError(errorMessage);
                }),
                finalize(() => {
                    // hide loading
                    this.cargandoService.ocultar(request.url);
                })
            );
    }
    
    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
        // If we do not have a token yet then we should not set the header.
        // Here we could first retrieve the token from where we store it.
        // console.log("addAuthenticationToken: " + request.url);
        if (!request.url.startsWith('http')) {
            request = request.clone({
                url: environment.baseURL + request.url
            });
        }

        if (!this.autorizacionService.getAccessToken()) {
            return request;
        } else {
        // If you are calling an outside domain then do not add the token.
            return request.clone({
                headers: request.headers.set(this.AUTH_HEADER, "Bearer " + this.autorizacionService.getAccessToken())
            });
        }
    }
}