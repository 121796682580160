import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CargandoService } from "@app/service/cargando.service";
import { CargandoEstado } from './cargando-estado.interface';
import * as jQuery from 'jquery';

@Component({
  selector: 'app-cargando',
  templateUrl: './cargando.component.html',
  styleUrls: ['./cargando.component.scss']
})
export class CargandoComponent implements OnInit {

  mostrar = false;
  mensaje = 'Cargando';
  verMensaje = false;
  private subscripcion!: Subscription;

  constructor(
    private cargandoService: CargandoService
  ) { }

  ngOnInit(): void {
    // console.log('[CargandoComponent.ngOnInit()] inicio');
    this.subscripcion = this.cargandoService.cargandoEstado
    .subscribe((estado: CargandoEstado) => {
        this.mostrar = estado.mostrar;
        if (this.mostrar) {
            jQuery('#cargandoModal').css('display', 'block');
        } else {
            setTimeout(() => {
                jQuery('#cargandoModal').css('display', 'none');
            }, 500);
        }
    });
  }

  ngOnDestroy() {
    // console.log('[CargandoComponent.ngOnDestroy()] inicio');
    this.subscripcion.unsubscribe();
  }

}
