// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  oauthURL: 'https://oauth.smartlokus.cl:8443/auth',

  // LOCALHOST
  // baseURL: 'http://192.168.0.108:5000/api',

  // AWS DEV
  // baseURL: 'https://apidev.smartlokus.cl/api',

  // AWS TEST
  // baseURL: 'https://apitest.smartlokus.cl/api',

  // AWS PROD
  baseURL: 'https://api.smartlokus.cl/api',

  version: '2.0.0-PROD',
  keycloak: {
    // Url of the Identity Provider
    url: 'https://oauth.smartlokus.cl:8443/auth/',

    redirectUri: 'https%3A%2F%2Ftest.smartlokus.cl',

    // Realm
    realm: 'ProcelRealm',

    // Client ID
    clientId: 'api-smartlokus-reload',
    clientSecret: '9241d531-1657-4914-aeea-ff2f5bc54662',
  },
  aws: {
    s3: {
      endpoint: 'https://s3.sa-east-1.amazonaws.com/'
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
