import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CargandoEstado } from '../common/cargando/cargando-estado.interface';

const prefix = '[CargandoService] ';

@Injectable({
  providedIn: 'root'
})
export class CargandoService {

    private cargandoSubject = new Subject<CargandoEstado>();
    private activado = true;
    cargandoEstado = this.cargandoSubject.asObservable();
    urls: Map<string, boolean> = new Map<string, boolean>();

    activar() {
        this.activado = true;
    }

    desactivar() {
        this.activado = false;
    }

    mostrar(url: string) {
        // console.log(prefix + "mostrar");
        if (this.activado) {
            this.urls.set(url, true);
            // console.log(prefix + ' mostrar() this.urls: ', this.urls);
            this.cargandoSubject.next({ mostrar: true } as CargandoEstado);
        }
    }

    ocultar(url: string) {
        // console.log(prefix + "ocultar");
        if (this.activado) {
            if (this.urls.has(url)) {
                this.urls.delete(url);
            }
            if (this.urls.size <= 0) {
                this.cargandoSubject.next({ mostrar: false } as CargandoEstado);
            }
        }
    }

    constructor() { }

}