import { Component } from '@angular/core';
import { AutorizacionService } from './core/security/autorizacion.service';
import { UsuarioOAuthKC } from './model/usuario-oauth-kc.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  user!: UsuarioOAuthKC;

  title = 'web-smartlokus-reload';

  constructor(private autorizacionService: AutorizacionService) {
    this.autorizacionService.user.subscribe(x => this.user = x);
  }

  logout() {
    this.autorizacionService.logout();
  }
}
