import { UsuarioOAuth } from "./usuario-oauth.model";
import { Usuario } from "./usuario.model";

export class RespuestaOAuth {
    accessToken!: string;
    expiresIn!: number;
    refreshExpiresIn!: number;
    refreshToken!: string;
    tokenType!: string;
    sessionState!: string;
    scope!: string;
    error!: string;
    errorDescription!: string;
    usuarioOAuth!: UsuarioOAuth;
    usuario!: Usuario;
}