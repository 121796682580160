<div class="d-flex flex-row bg-warning pt-3">
  <div class="container">
  <div class="row">
    <div class="col mt-2">
      <img class="logo" src="assets/img/landing/logo_procel_blanco.png" alt="Logotipo procel">
    </div>
    <!-- <hr class="clearfix w-100 d-md-none pb-3"> -->
    <!-- Grid column -->
    <div class="col text-center">
      <p>
        Manuel Bulnes 921,<br>
        Salamanca, Chile.<br>
        Teléfono: +56 5 3255 3327
      </p>
    </div>
    <!-- Copyright -->
    <div class="col footer-copyright text-right mt-2">
      <b>© 2023 Copyright:</b>
      <a href="https://smartlokus.com/"> smartlokus.com</a>
      <p class="version_negrita">{{ version }}</p>
    </div>
  </div>
</div>
</div>
