    <div class="col-2 sidebar_" *ngIf="clienteNavbar">
      <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" *ngFor="let funcion of funcionesGenerales">
        <a class="nav-link" data-toggle="pill" role="tab" aria-selected="true" 
          routerLinkActive="active" [routerLink]="[funcion.url]"
          *ngIf="(funcion.codigo === 'ADM_DASHBOARD' || funcion.codigo === 'CLI_PROYECTOS_CLIENTE' || funcion.codigo === 'ADM_REPORTES') && clienteNavbar"
          [queryParams]="{codigo:funcion.codigo}">
          {{ funcion.nombre }}
        </a>
      </div>
    </div>
    <div class="col-2 sidebar_" *ngIf="!clienteNavbar">
      <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical" *ngFor="let funcion of funcionesGenerales">
        <a class="nav-link" data-toggle="pill" role="tab" aria-selected="true" 
          routerLinkActive="active" [routerLink]="[funcion.url]" 
          [queryParams]="{codigo:funcion.codigo}">
          {{ funcion.nombre }}
        </a>
      </div>
    </div>