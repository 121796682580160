export class FuncionesUtil {
    public static calculoDV(rut: number): string {

        let suma = 0;
        let multiplo = 2;
        let rutStr: string[] = rut.toString().split('').reverse();

        for (let i = 0; i < rutStr.length; i++) {
            let numero: number = +rutStr[i];

            suma = suma + multiplo * numero;

            if (multiplo < 7) {
                multiplo = multiplo + 1;
            } else {
                multiplo = 2;
            }
        }
        let dv: string = '';
        const calculo = 11 - (suma % 11);
        if (calculo == 11) {
            dv = '0';
        } else if (calculo == 10) {
            dv = 'K';
        } else {
            dv = calculo.toString();
        }

        return dv;
    }

    public static validarPermiso(permiso: string): boolean {
        let permisos: Array<String> = JSON.parse(sessionStorage.getItem('permisos')!);
        if (permisos.includes("ACC_" + permiso)) {
            return true;
        } else {
            return false;
        }
    }

    public static downloadFile(data: Blob, filename: string, mime: string) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const blob = new Blob([data], { type: mime || 'application/octet-stream' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE doesn't allow using a blob object directly as link href.
          // Workaround for "HTML7007: One or more blob URLs were
          // revoked by closing the blob for which they were created.
          // These URLs will no longer resolve as the data backing
          // the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
          return;
        }
        // Other browsers
        // Create a link pointing to the ObjectURL containing the blob
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', filename);
        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobURL);
        }, 100);
      }
    public static viewFile(data: Blob, filename: string, mime: string) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const blob = new Blob([data], { type: mime || 'application/octet-stream' });
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE doesn't allow using a blob object directly as link href.
          // Workaround for "HTML7007: One or more blob URLs were
          // revoked by closing the blob for which they were created.
          // These URLs will no longer resolve as the data backing
          // the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
          return;
        }
        var image = new Image();
        image.src = URL.createObjectURL(blob);
        document.body.appendChild(image);
        image.style.display = 'none';
        // console.info('funciones-utils',image)
        return image.src
      }
}