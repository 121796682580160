<!-- This flex container takes the full height -->
<div class="d-flex flex-column min-vh-100 fondo_color">
    <app-header-website></app-header-website>
    <!-- The main area does not shrink, 'pushing down' the footer -->
    <main class="container-fluid m-o p-0">
      <!-- This will render the routes -->
      <router-outlet></router-outlet>
    </main>
    <!-- This keeps the footer down if the main content does not fill up the space -->
    <footer class="page-footer font-small blue mt-auto">
      <app-footer-website></app-footer-website>
    </footer>
</div>