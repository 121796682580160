import { NgModule } from "@angular/core";
import { IconsModule, InputsModule } from "angular-bootstrap-md";

@NgModule({
    imports: [
        IconsModule,
        InputsModule
    ],
    declarations: [
    ],
    providers: [
    ],
    exports: [
        IconsModule,
        InputsModule
    ]
})
export class MdBoostrapCustomModule {
    
}