// import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
// FRAMEWORK
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { IconsModule, InputsModule } from 'angular-bootstrap-md';
import { AlertComponent } from './alert/alert.component';
import { CargandoComponent } from './cargando/cargando.component';
// // MODULOS
import { HasPermissionDirective } from './directive/has-permission.directive';
import { MdBoostrapCustomModule } from './md-boostrap.custom.module';
import { SubirArchivoComponent } from './subir-archivo/subir-archivo.component';
import { HasButtonPermissionDirective } from './directive/has-button-permission.directive';

// // MODEL

// // SERVICE

// // COMPONENT

// // DIRECTIVAS

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MdBoostrapCustomModule,
        FormlyMaterialModule,
        FormlyModule.forRoot({
        extras: {
            lazyRender: true
        },
        // validationMessages: [
                // 	{ name: 'required', message: validateRequired },
                // 	{ name: 'minlength', message: validateMinLength },
                // 	{ name: 'maxlength', message: validateMaxLength },
                // 	{ name: 'min', message: validateMin },
                // 	{ name: 'max', message: validateMax },
                // ],
        }),

    ],
    declarations: [
        AlertComponent,
        CargandoComponent,
        // SubirArchivoComponent,
        HasPermissionDirective,
        HasButtonPermissionDirective
    ],
    providers: [
        HttpClientModule,
        // ValidacionService,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AlertComponent,
        CargandoComponent,
        // SubirArchivoComponent,
        MdBoostrapCustomModule,
        FormlyMaterialModule,
        FormlyModule,
        HasPermissionDirective,
        HasButtonPermissionDirective
    ]
})
export class ComunModule {
}
