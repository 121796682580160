import { RespuestaOAuth } from "./respuesta-oauth.model";
import { UsuarioOAuthKC } from "./usuario-oauth-kc.model";

export class RespuestaOAuthKC {
    access_token!: string;
    expires_in!: number;
    refresh_expires_in!: number;
    refresh_token!: string;
    token_type!: string;
    session_state!: string;
    scope!: string;
    error!: string;
    error_description!: string;
    usuarioOAuthKC!: UsuarioOAuthKC;

    constructor(obj: RespuestaOAuth) {
        this.access_token = obj.accessToken;
        this.expires_in = obj.expiresIn;
        this.refresh_expires_in = obj.refreshExpiresIn;
        this.refresh_token = obj.refreshToken;
        this.error = obj.error;
        this.error_description = obj.errorDescription;
    }
}