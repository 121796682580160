import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private showBreadcumb$ = new Subject<boolean>();
  private nombreCliente$ = new Subject<String>();
  private nombreProyecto$ = new Subject<String>();
  private nombreInfraestructura$ = new Subject<String>();

  constructor() { }

  getShowBreadcumb$(): Observable<boolean> {
    return this.showBreadcumb$.asObservable();
  }

  updateBreadcumb(data: boolean) {
    this.showBreadcumb$.next(data);
  }

  getNombreCliente$(): Observable<String> {
    return this.nombreCliente$.asObservable();
  }

  updateNombreCliente(data: String) {
    this.nombreCliente$.next(data);
  }

  getNombreProyecto$(): Observable<String> {
    return this.nombreProyecto$.asObservable();
  }

  updateNombreProyecto(data: String) {
    this.nombreProyecto$.next(data);
  }

  getNombreInfraestructura$(): Observable<String> {
    return this.nombreInfraestructura$.asObservable();
  }

  updateNombreInfraestructura(data: String) {
    this.nombreInfraestructura$.next(data);
  }  
}
