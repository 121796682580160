<div class="cargando" id="cargandoModal">
    <div class="cargando-contenido">
        <div class="cargando-imagen">
            <!--<img src="assets/img/pdvload2.svg">-->
            <svg class="spinner" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
            </svg>
        </div>
        <div class="cargando-mensaje" *ngIf="verMensaje">
            <h4>{{mensaje}}</h4>
        </div>
    </div>
</div>