import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-footer-website',
  templateUrl: './footer-website.component.html',
  styleUrls: ['./footer-website.component.scss']
})
export class FooterWebsiteComponent implements OnInit {

  version: string = environment.version;
  
  constructor() { }

  ngOnInit(): void {
  }

}
