import { Directive, HostBinding, Input } from '@angular/core';
import { FuncionesUtil } from '../util/funciones-util';

@Directive({
  selector: '[buttonPermission]'
})
export class HasButtonPermissionDirective {

  private permiso!: string;

  @HostBinding('class.fTransparent')
  transparente!: boolean | null;

  @HostBinding('disabled')
  disabled!: boolean | null;

  ngOnInit(): void {
    this.applyPermission();
  }

  @Input()
  set hasButtonPermission(checkedPermissions: string) {
    this.permiso = checkedPermissions;
  }

  private applyPermission(): void {
    if (FuncionesUtil.validarPermiso(this.permiso)) {
      this.transparente = null;
      this.disabled = null;
    } else {
      this.transparente = true;
      this.disabled = true;
    }
  }

  ngOnDestroy(): void {
  }
}
