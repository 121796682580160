import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutorizacionService } from '@app/core/security/autorizacion.service';
import { AlertService } from '@app/service/alert.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  loading = false;

  constructor(
    private readonly router: Router,
    private autorizacionService:AutorizacionService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
  }

  public submit() {
    this.autorizacionService.logout()
    .pipe(first())
      .subscribe(
          data => {
            // Navigate after successful logout
            // this.alertService.success('Salida Exitosa', 'Smartlokus');
            this.router.navigate(['/']);
          },
          error => {
            this.alertService.error(error, 'Error al salir');
            this.loading = false;
          }
      );

    console.log("logout");
  }
}
