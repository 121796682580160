import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AutorizacionService } from '@app/core/security/autorizacion.service';
import { Accion } from '@app/model/accion.model';
import { Subscription } from 'rxjs';
import { FuncionesUtil } from '../util/funciones-util';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  // private permissionSub: Subscription;
  private permiso!: string;

  constructor(private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private autorizacionService: AutorizacionService) {
  }

  ngOnInit(): void {
    this.applyPermission();
  }

  @Input()
  set hasPermission(checkedPermissions: string) {
    // The input where we set the values of our directive
    this.permiso = checkedPermissions;
  }

  private applyPermission(): void {
    if (FuncionesUtil.validarPermiso(this.permiso)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  ngOnDestroy(): void {
  }

}
